<template>
  <v-row>

    <v-col>
      <!-- Filter By Doctor -->
      <div class="row justify-content-end align-items-center mb-2">
        <div class="col-md-3 col-12">
          <treeselect
            id="input-doctor"
            v-model="filter.doctor_id"
            :multiple="false"
            :options="doctors"
            placeholder="Filter Berdasar Dokter"
            @select="filterDoctorOnSelect"
          />
        </div>
      </div>
      <!-- Calendar -->
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Per Hari</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Per Minggu</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Per Bulan</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>Per 4 Hari</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="value"
          locale="id"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @click:event="showEvent"
          color="primary"
          @change="getEvents"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-toolbar-title v-html="`${selectedEvent.name}`"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text style="font-size: 13px">
              <p class="py-0">{{ selectedEvent.details }}</p>
              <p class="py-0">Keterangan : {{ selectedEvent.notes }}</p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>

  </v-row>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {

  components: {
    Treeselect
  },

  data: () => ({
    // Filter
    filter: {
      doctor_id: ''
    },
    // Filter Options
    doctors: [],
    // Calendar
    type: 'month',
    typeToLabel: {
      month: 'Per Bulan',
      week: 'Per Minggu',
      day: 'Per Hari',
      '4day': 'Per 4 Hari',
    },
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    // Event
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),

  methods: {

    async getDoctors() {
      let response = await module.setDoctorTreeSelect('doctors')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
        this.doctors.unshift({ label: 'Filter Berdasar Dokter', id: '', isDisabled: true })
      }
    },

    async getHoliday() {
      return await module.list(`holidays/get-calendar?doctor_id=${this.filter.doctor_id}`)
    },

    async filterDoctorOnSelect(evt) {
      await this.filter.doctor_id
      this.getEvents()
    },

    async getEvents() {
      const events = []
      const holidays = await this.getHoliday(this.filter.doctor_id)
      const eventCount = holidays.length
      for (let i = 0; i < eventCount; i++) {
        events.push({
          name: holidays[i].name,
          start: new Date(holidays[i].start_date),
          end: holidays[i].end_date != "" ? new Date(holidays[i].end_date) : "",
          notes: holidays[i].notes,
          color: this.colors[this.random(0, this.colors.length - 1)],
          timed: true,
        })
      }
      this.events = events
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        let startDate = new Date(event.start).toLocaleDateString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }).replaceAll('/', '-')
        this.selectedEvent.details = `${event.name} Pada ${startDate}`
        if (event.end != "") {
          let endDate = new Date(event.end).toLocaleDateString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }).replaceAll('/', '-')
          this.selectedEvent.details += ` s/d ${endDate}`
        }
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 30)
      }
      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 30)
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },

    viewDay({ date }) {
      this.value = date
      this.type = 'day'
    },

    setToday() {
      this.value = ''
    },

    prev() {
      this.$refs.calendar.prev()
    },

    next() {
      this.$refs.calendar.next()
    },

    getEventColor(event) {
      return event.color
    },

    random(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

  },

  watch: {
    'filter.doctor_id': function (newVal, oldVal) {
      if (typeof newVal === 'undefined') {
        this.filter.doctor_id = ''
        this.filterDoctorOnSelect()
      }
    }
  },

  mounted() {
    this.$refs.calendar.scrollToTime('08:00')
    this.getDoctors()
  },

}
</script>

<style scoped>
/* .my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
} */
</style>