<template>
  <div>

    <div>
      <!-- Filter By Doctor -->
      <div
        class="row justify-content-end align-items-center"
        v-if="!hasDoctor"
      >
        <div class="col-md-3 col-12">
          <treeselect
            id="input-doctor"
            v-model="filter.doctor_id"
            :multiple="false"
            :options="doctors"
            placeholder="Filter Berdasar Dokter"
            @select="filterDoctorOnSelect"
          />
        </div>
      </div>
      <b-table
        striped
        hover
        responsive
        class="mt-3"
        style="white-space: nowrap"
        :items="items"
        :fields="fields"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '10%' : '' }"
          />
        </template>
        <template #cell(date)="data">
          <div>
            {{ new Date(Date.parse(data.item.start_date)).toLocaleString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }).replaceAll('/', '-') }}
            <span v-if="data.item.end_date != ''"> <b>s/d</b> {{ new Date(Date.parse(data.item.end_date)).toLocaleString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }).replaceAll('/', '-') }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            @click="btnEditOnClick(data.item.id)"
            v-b-modal.modal-form
            v-if="manipulateBtn ==  true"
          ><i class="fas fa-edit px-0"></i></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Hapus"
            v-if="manipulateBtn ==  true"
            @click="btnDeleteOnClick(data.item.id)"
          ><i class="fas fa-trash px-0"></i></b-button>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </div>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {

  components: {
    Treeselect
  },

  props: {
    fields: Array,
    items: Array,
    hasDoctor: Boolean,
    perPage: Number,
    currentPage: Number,
    totalRows: Number
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: ''
      },
      // Doctors
      doctors: [],
      // access management
      manipulateBtn: false,
    }
  },

  methods: {

    async getDoctors() {
      let response = await module.setDoctorTreeSelect('doctors')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
        this.doctors.unshift({ label: 'Filter Berdasar Dokter', id: '', isDisabled: true })
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.$emit('pageOnClick', page)
    },

    async filterDoctorOnSelect(evt) {
      await this.filter.doctor_id
      this.$emit('filterDoctorOnSelect', this.filter.doctor_id)
    },

    btnEditOnClick(id) {
      this.$emit('btnEditOnClick', id)
    },

    btnDeleteOnClick(id) {
      this.$emit('btnDeleteOnClick', id)
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")

        if (access_right[a] == "4004") {
          this.manipulateBtn = true
        }

      }
    },

  },

  watch: {
    'filter.doctor_id': function (newVal, oldVal) {
      if (typeof newVal === 'undefined') {
        this.filter.doctor_id = ''
        this.filterDoctorOnSelect()
      }
    }
  },

  mounted() {
    this.setActiveMenu()
    if (!this.hasDoctor) this.getDoctors()
  }

}
</script>

<style>
</style>