<template>
  <v-app>
    <div>

      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">
          Daftar <strong>Hari Libur Dokter</strong> Klinik IONA
        </div>
        <b-button
          squared
          variant="success"
          @click="btnAddOnClick"
          v-b-modal.modal-form
        >Tambah</b-button>
      </b-alert>

      <!-- Holiday List -->
      <div class="row">
        <div class="col-md-12">
          <Card>
            <template v-slot:body>
              <b-tabs content-class="mt-3">
                <b-tab
                  title="Kalender"
                  active
                  @click="btnTabCalendarOnClick"
                >
                  <Calendar v-if="renderComponent" />
                </b-tab>
                <b-tab
                  title="Tabel"                  
                >
                  <Table
                    :fields="fields"
                    :items="items"
                    :perPage="perPage"
                    :currentPage="currentPage"
                    :totalRows="totalRows"
                    :hasDoctor="false"
                    @pageOnClick="pageOnClick"
                    @btnEditOnClick="btnEditOnClick"
                    @btnDeleteOnClick="btnDeleteOnClick"
                    @filterDoctorOnSelect="filterDoctorOnSelect"
                  />
                </b-tab>                
              </b-tabs>
            </template>
          </Card>
        </div>
      </div>

      <!-- Modal Form -->
      <Form
        :form="form"
        :route="route"
        :editTarget="editTarget"
        :hasDoctor="false"
        @formOnSubmit="formOnSubmit"
      />

    </div>
  </v-app>
</template>

<script>
import Form from '@/component/holidays/Form.vue'
import Table from '@/component/holidays/Table.vue'
import Card from "@/view/content/Card.vue"
import Calendar from '@/component/holidays/Calendar.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form,
    Table,
    Calendar
  },

  data() {
    return {
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Filter
      filter: {
        doctor_id: ''
      },
      // Form
      form: {
        doctor_id: '',
        start_date: '',
        end_date: '',
        notes: ''
      },
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: 'doctor_name',
          label: 'Dokter',
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Other
      route: 'holidays',
      editTarget: '',
      renderComponent: true,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&doctor_id=${this.filter.doctor_id}`
      let response = await module.paginate('holidays', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1  
      for(a = 0; a < response.data.length; a++){
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    btnTabCalendarOnClick() {
      // Remove my-component from the DOM
      this.renderComponent = false
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

    formOnSubmit() {
      this.btnTabCalendarOnClick()
      this.pagination()
    },

    async filterDoctorOnSelect(doctorId) {
      this.filter.doctor_id = doctorId
      this.pagination()
    },

    btnAddOnClick() {
      this.editTarget = ""
    },

    async btnEditOnClick(id) {
      this.editTarget = id.toString()
      this.form = await module.get('holidays/' + id)
      this.form['_method'] = 'put'
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('holidays/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Jadwal", route: "" },
      { title: "Hari Libur" },
    ])
    // Get Data
    this.pagination()
  },

}

</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";
.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>